import { $authHost } from './api'
import { AxiosResponse } from 'axios'
import { IChangeReferralProgramResponse, IReferralProgramResponse } from '@/types/referralProgramTypes'

class ReferralProgramService {
  async changeReferralProgram(
    transfer_type: string,
    partner: string,
    transfer_at: string | undefined,
    shopId: number
  ): Promise<IChangeReferralProgramResponse> {
    console.log('changeReferralProgram');

    const payload: Record<string, any> = {
      transfer_type,
      partner
    };

    if (transfer_at) {
      payload.transfer_at = transfer_at; // Добавляем дату только если она есть
    }

    const { data }: AxiosResponse<IChangeReferralProgramResponse> = await $authHost.post(
      `/referral-program/${shopId}`,
      payload
    );

    return data;
  }

  async fetch(shopId: number): Promise<IReferralProgramResponse> {
    const { data }:AxiosResponse<IReferralProgramResponse> = await $authHost.get(`/referral-program/${shopId}`)
    return data
  }
}

export default new ReferralProgramService()
